const roleValidation = role => {
  for (let i = 0; i < role.length; i++) {
    if (
      JSON.parse(localStorage.getItem("decoded_access_token"))?.role.includes(
        role[i]
      )
    ) {
      return true
    }
  }
  return false
}

export default roleValidation
