import React, { useState, useEffect } from "react"
import { Col, Row, Card, CardBody, Button } from "reactstrap"

import MetaTags from "react-meta-tags"

import { connect } from "react-redux"
import { getHubById, deleteHub } from "client/ApiClient"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

//Import Images
import imgdark from "../../assets/images/Transparent.png"
import SweetAlert from "react-bootstrap-sweetalert"

import moment from "moment"

const HubDetails = props => {
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  let id = props.match.params.hubId
  const { history } = props

  const breadcrumbItems = [
    { title: "Zarela", link: "/" },
    { title: "Hubs", link: "/hubs" },
    { title: "Hub Details", link: "#" },
  ]

  useEffect(async () => {
    if (id) {
      const response = await getHubById(id)
      if (response.data.hub) {
        setData(response.data.hub)
        setIsLoading(false)
      }
    }
  }, [id, isLoading])

  useEffect(() => {
    props.setBreadcrumbItems("Hub Details", breadcrumbItems)
  })

  const handleDelete = async e => {
    deleteHub(id)
      .then(response => {
        if (response.status === 200) {
          history.push("/hubs")
          setsuccess_msg(true)
          setsuccess_dlg(true)
          setdynamic_title("Deleted")
          setdynamic_description("Hub has been deleted.")
          setconfirm_alert(false)
        }
      })
      .catch(error => {
        setsuccess_msg(true)
        setsuccess_dlg(true)
        setdynamic_title("Error")
        setdynamic_description("Something went wrong")
        setconfirm_alert(false)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hub Details</title>
      </MetaTags>
      {showErrorMessage && (
        <SweetAlert
          confirmBtnText="Ok"
          confirmBtnBsStyle="error"
          title="Error"
          onConfirm={() => {
            setShowErrorMessage(false)
          }}
        >
          {errorMessage}
        </SweetAlert>
      )}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleDelete()
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className="invoice-title d-flex w-100 justify-content-between align-items-center">
                    <h4 className="float-end font-size-16 m-0">
                      <span>Hub Submit Date : </span>
                      <span>
                        {moment(data?.created_at).format("YYYY-MM-DD")}
                      </span>
                    </h4>
                    <h3 className="mt-0">
                      <img src={imgdark} alt="logo" height="35" />
                    </h3>
                  </div>
                  <hr />
                  <Row>
                    <Col xs={6} className="mb-3">
                      <span>logo : </span> <br />
                      <img src={data?.logo} height={60} />
                    </Col>
                    <Col xs={6} className="mb-3">
                      <span>Cordinator Profile : </span> <br />
                      <img src={data?.coordinatorProfile} height={60} />
                    </Col>
                    <Col xs={6} className="mb-3">
                      <span>Name : </span> <strong>{data?.name}</strong>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <span>Coordinator Name : </span>{" "}
                      <strong>{data?.coordinatorName}</strong>
                    </Col>

                    <Col xs={6} className="mb-3">
                      <span>Country : </span> <strong>{data?.country}</strong>
                    </Col>

                    <Col xs={6} className="mb-3">
                      <span>Coordinator Phone : </span>{" "}
                      <strong>{data?.coordinatorPhone}</strong>
                    </Col>

                    <Col xs={6} className="mb-3">
                      <span>City : </span> <strong>{data?.city}</strong>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <span>Time Zone : </span>{" "}
                      <strong>{data?.timeZone}</strong>
                    </Col>

                    <Col xs={12} className="mb-3">
                      <span>Address : </span> <strong>{data?.address}</strong>
                    </Col>

                    <Col xs={12} className="mb-3">
                      <span>Email : </span> <strong>{data?.email}</strong>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <span>Website : </span> <strong>{data?.website}</strong>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <span>Phone : </span> <strong>{data?.phone}</strong>
                    </Col>

                    <Col xs={12} className="mb-3">
                      <span>working Hours : </span>
                      {data?.workingHours.map((item, index) => (
                        <strong>{item} ,</strong>
                      ))}
                    </Col>

                    <Col xs={12} className="mb-3">
                      <span>Equipments : </span>
                      {data?.equipments.map((item, index) => (
                        <strong>{item} , </strong>
                      ))}
                    </Col>

                    <Col xs={12} className="mb-3">
                      <Button
                        type="button"
                        color="danger"
                        size="md"
                        className="waves-effect waves-light"
                        onClick={() => setconfirm_alert(true)}
                      >
                        Delete Hub
                      </Button>
                      <Button
                        type="button"
                        color="success"
                        size="md"
                        className="waves-effect waves-light m-2"
                        onClick={() => history.push("/hub/form/" + id)}
                      >
                        Update Hub
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(HubDetails)
