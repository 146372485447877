import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { createMedia } from "client/ApiClient"
import SweetAlert from "react-bootstrap-sweetalert"

const AddLibrary = props => {
  const [mediaTitle, setMediaTitle] = useState(false)
  const [file, setFile] = useState(null)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const breadcrumbItems = [
    { title: "zarela", link: "/" },
    { title: "library", link: "/library" },
    { title: "new", link: "/add-library" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Library", breadcrumbItems)
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    var validationLibraryTitle = document.getElementById(
      "validationLibraryTitle"
    ).value

    if (validationLibraryTitle === "") {
      setMediaTitle(false)
    } else {
      setMediaTitle(true)
    }

    var d1 = document.getElementsByName("validate")
    for (var i = 0; i < d1.length; i++) {
      d1[i].style.display = "block"
    }

    if (mediaTitle && file) {
      let response
      const formData = new FormData()
      formData.append("title", validationLibraryTitle)
      formData.append("file", file)

      try {
        response = await createMedia(formData)
        if (response) {
          if (response.status === 201) {
            setShowSuccessMessage(true)
          }
        }
      } catch (err) {
        setErrorMessage(err.response.data.message)
        setShowErrorMessage(true)
      }
    }
  }

  return (
    <>
      <MetaTags>
        <title>Zarela | Create Library</title>
      </MetaTags>
      <Row>
        {showSuccessMessage && (
          <SweetAlert
            confirmBtnText="Ok"
            confirmBtnBsStyle="success"
            title="Success"
            onConfirm={() => {
              setShowSuccessMessage(false)
            }}
          >
            Media was created successfuly
          </SweetAlert>
        )}
        {showErrorMessage && (
          <SweetAlert
            confirmBtnText="Ok"
            confirmBtnBsStyle="error"
            title="Error"
            onConfirm={() => setShowErrorMessage(false)}
          >
            {errorMessage}
          </SweetAlert>
        )}
        <Col xl={12}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">create new Media form</h4>
              <AvForm
                className="needs-validation"
                onSubmit={e => handleSubmit(e)}
              >
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationLibraryTitle">
                        Media Title
                      </Label>
                      <AvField
                        name="title"
                        placeholder="library title"
                        type="text"
                        errorMessage="Enter Library Title"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="validationLibraryTitle"
                      />
                    </div>
                  </Col>

                  <div className="mb-3">
                    <label className="form-lable">File</label>
                    <input
                      type="file"
                      className="form-control form-control-file"
                      data-buttonname="btn-secondary"
                      onChange={acceptedFiles => {
                        setFile(acceptedFiles.target.files[0])
                      }}
                    />
                  </div>
                </Row>
                <Button color="primary mt-3" type="submit">
                  Create Media
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(AddLibrary)
