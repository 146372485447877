import React, { useState, useEffect } from "react"
import { Col, Row, Card, CardBody, Button } from "reactstrap"

import MetaTags from "react-meta-tags"

import { connect } from "react-redux"
import { getRequestById, downloadRequestFile } from "client/ApiClient"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

//Import Images
import imgdark from "../../assets/images/Transparent.png"
import SweetAlert from "react-bootstrap-sweetalert"
import fileDownload from "js-file-download"

import moment from "moment"

const RequestDetails = props => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  let id = props.match.params.requestId

  const breadcrumbItems = [
    { title: "Zarela", link: "/" },
    { title: "Requests", link: "/requests" },
    { title: "Request Details", link: "#" },
  ]

  useEffect(async () => {
    if (id) {
      const response = await getRequestById(id)
      if (response.data.request) {
        setData(response.data.request)
        setIsLoading(false)
      }
    }
  }, [id, isLoading])

  useEffect(() => {
    props.setBreadcrumbItems("Request Details", breadcrumbItems)
  })

  const handleDownload = async e => {
    e.preventDefault()
    downloadRequestFile(id)
      .then(res => {
        fileDownload(res.data, id + ".zip")
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Request Details</title>
      </MetaTags>
      {showErrorMessage && (
        <SweetAlert
          confirmBtnText="Ok"
          confirmBtnBsStyle="error"
          title="Error"
          onConfirm={() => {
            setShowErrorMessage(false)
          }}
        >
          {errorMessage}
        </SweetAlert>
      )}
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className="invoice-title d-flex w-100 justify-content-between align-items-center">
                    <h4 className="float-end font-size-16 m-0">
                      <span>Request Date : </span>
                      <span>
                        {moment(data?.created_at).format("YYYY-MM-DD")}
                      </span>
                    </h4>
                    <h3 className="mt-0">
                      <img src={imgdark} alt="logo" height="35" />
                    </h3>
                  </div>
                  <hr />
                  <Row>
                    <Col xs={12} className="mb-3">
                      <span>Title : </span> <strong>{data?.title}</strong>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <span>Full name :</span> <strong>{data?.fullname}</strong>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <span>Email :</span> <strong>{data?.email}</strong>
                    </Col>

                    <Col xs={6} className="mb-3">
                      <span>Required found : </span>{" "}
                      <strong>{data?.requiredfound}</strong>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <span>Contributors : </span>{" "}
                      <strong>{data?.contributors}</strong>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <span>Description : </span>{" "}
                      <strong>{data?.description}</strong>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <Button
                        type="button"
                        color="primary"
                        size="md"
                        className="waves-effect waves-light"
                        onClick={e => handleDownload(e)}
                      >
                        Download attached file
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(RequestDetails)
