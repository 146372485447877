import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  createArticle,
  getAllKeywords,
  getAllCategories,
  getAllTags,
} from "client/ApiClient"
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CustomUploadAdapterPlugin } from "./uploadConfigs"

const animatedComponents = makeAnimated()

const editorConfiguration = {
  extraPlugins: [CustomUploadAdapterPlugin],
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: {
          name: "h1",
          element: "h1",
        },
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: {
          name: "h2",
          element: "h2",
        },
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: {
          name: "h3",
          element: "h3",
        },
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: {
          name: "h4",
          element: "h4",
        },
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: {
          name: "h5",
          element: "h5",
        },
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
    ],
  },
}

const AddArticle = props => {
  const { history } = props
  const [articleCoverImg, setArticleCoverImg] = useState(null)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [editor, setEditor] = useState(null)

  const [keywords, setKeywords] = useState([])
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])

  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  let optionKeywords = [
    {
      options: keywords,
    },
  ]

  let optionCategories = [
    {
      options: categories,
    },
  ]

  let optionTags = [
    {
      options: tags,
    },
  ]

  const getKeywords = async () => {
    getAllKeywords()
      .then(res => {
        const data = res.data.keywords
        data.map((item, index) => {
          setKeywords(prev => [...prev, { value: item.slug, label: item.name }])
        })
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const getCategories = async () => {
    getAllCategories()
      .then(res => {
        const data = res.data.categories
        data.map((item, index) => {
          setCategories(prev => [
            ...prev,
            { value: item.slug, label: item.title },
          ])
        })
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const getTags = async () => {
    getAllTags()
      .then(res => {
        const data = res.data.tags
        data.map((item, index) => {
          setTags(prev => [...prev, { value: item.slug, label: item.title }])
        })
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  useEffect(() => {
    getKeywords()
    getCategories()
    getTags()
  }, [])

  function handleKeywords(list) {
    setSelectedKeywords(list)
  }

  function handleCategories(list) {
    setSelectedCategories(list)
  }
  function handleTags(list) {
    setSelectedTags(list)
  }

  const breadcrumbItems = [
    { title: "zarela", link: "/" },
    { title: "articles", link: "/articles" },
    { title: "new", link: "/add-article" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Articles", breadcrumbItems)
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    var validationArticleTitle = document.getElementById(
      "validationArticleTitle"
    ).value
    var validationArticleDescription = document.getElementById(
      "validationArticleDescription"
    ).value
    var validationArticleBody = editor
    var validationArticleSlug = document.getElementById(
      "validationArticleSlug"
    ).value

    var d1 = document.getElementsByName("validate")
    for (var i = 0; i < d1.length; i++) {
      d1[i].style.display = "block"
    }

    let response
    const formData = new FormData()
    formData.append("title", validationArticleTitle)
    formData.append("description", validationArticleDescription)
    formData.append("body", validationArticleBody)
    formData.append("slug", validationArticleSlug)
    formData.append("cover", articleCoverImg)
    formData.append(
      "categories",
      JSON.stringify(
        selectedCategories.map((item, index) => {
          return item.value
        })
      )
    )
    formData.append(
      "keywords",
      JSON.stringify(
        selectedKeywords.map((item, index) => {
          return item.value
        })
      )
    )
    formData.append(
      "tags",
      JSON.stringify(
        selectedTags.map((item, index) => {
          return item.value
        })
      )
    )

    try {
      response = await createArticle(formData)
      if (response) {
        if (response.status === 201) {
          setShowSuccessMessage(true)
          history.push("articles")
        }
      }
    } catch (err) {
      setErrorMessage(err.response.data.message)
      setShowErrorMessage(true)
    }
  }

  return (
    <>
      <MetaTags>
        <title>Zarela | Create Article</title>
      </MetaTags>
      <Row>
        {showSuccessMessage && (
          <SweetAlert
            confirmBtnText="Ok"
            confirmBtnBsStyle="success"
            title="Success"
            onConfirm={() => {
              setShowSuccessMessage(false)
            }}
          >
            Article was created successfuly
          </SweetAlert>
        )}
        {showErrorMessage && (
          <SweetAlert
            confirmBtnText="Ok"
            confirmBtnBsStyle="error"
            title="Error"
            onConfirm={() => setShowErrorMessage(false)}
          >
            {errorMessage}
          </SweetAlert>
        )}
        <Col xl={12}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">create new article form</h4>
              <AvForm
                className="needs-validation"
                onSubmit={e => handleSubmit(e)}
              >
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationArticleTitle">
                        Article Title
                      </Label>
                      <AvField
                        name="title"
                        placeholder="article title"
                        type="text"
                        errorMessage="Enter Article Name"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="validationArticleTitle"
                      />
                    </div>
                  </Col>

                  <div className="mb-3 templating-select select2-container">
                    <label className="control-label">Keywords</label>
                    <Select
                      value={selectedKeywords}
                      isMulti={true}
                      onChange={e => {
                        handleKeywords(e)
                      }}
                      options={optionKeywords}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                    />
                  </div>
                  <div className="mb-3 templating-select select2-container">
                    <label className="control-label">Categories</label>
                    <Select
                      value={selectedCategories}
                      isMulti={true}
                      onChange={e => {
                        handleCategories(e)
                      }}
                      options={optionCategories}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                    />
                  </div>
                  <div className="mb-3 templating-select select2-container">
                    <label className="control-label">Tags</label>
                    <Select
                      value={selectedTags}
                      isMulti={true}
                      onChange={e => {
                        handleTags(e)
                      }}
                      options={optionTags}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                    />
                  </div>

                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationArticleTitle">
                        Article Slug
                      </Label>
                      <AvField
                        name="slug"
                        placeholder="article slug"
                        type="text"
                        errorMessage="Enter slug"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="validationArticleSlug"
                      />
                    </div>
                  </Col>
                  <div className="mb-3">
                    <label className="form-lable">Cover Image</label>
                    <input
                      type="file"
                      className="form-control form-control-file"
                      data-buttonname="btn-secondary"
                      onChange={acceptedFiles => {
                        setArticleCoverImg(acceptedFiles.target.files[0])
                      }}
                    />
                  </div>

                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        className="mb-3"
                        type="textarea"
                        label="Brief Description "
                        name="description"
                        id="validationArticleDescription"
                        rows="5"
                        placeholder="Enter Article Brief Description"
                        errorMessage="This value is required."
                        validate={{
                          required: { value: true },
                          minLength: {
                            value: 120,
                            errorMessage: "Min 120 chars.",
                          },
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="12">
                    <Label htmlFor="validationArticleTitle">Article Body</Label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setEditor(data)
                      }}
                    />
                  </Col>
                </Row>
                <Button color="primary mt-3" type="submit">
                  Create Article
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(AddArticle)
