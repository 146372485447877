import { actionTypes } from "./actionTypes"

export const setAccessToken = (dispatch, payload) => {
  localStorage.setItem("access_token", payload)
  dispatch({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: payload,
  })
}

export const setDecodedAccessToken = (dispatch, payload) => {
  localStorage.setItem("decoded_access_token", JSON.stringify(payload))
  dispatch({ type: actionTypes.SET_DECODED_ACCESS_TOKEN, payload: payload })
}

export const clearAccessToken = dispatch => {
  localStorage.removeItem("access_token")
  localStorage.removeItem("decoded_access_token")
  dispatch({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: null,
  })
  dispatch({ type: actionTypes.SET_DECODED_ACCESS_TOKEN, payload: null })
}
