import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, Button, Modal } from "reactstrap"
import { setBreadcrumbItems } from "../../store/actions"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select"
import {
  getHubById,
  updateHub,
  uploadImage,
  getCountries,
  getState,
} from "client/ApiClient"

const UpdateHubForm = props => {
  const { history } = props
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [vLogo, setvLogo] = useState(null)
  const [vCoordinatorProfile, setvCoordinatorProfile] = useState(null)

  let id = props.match.params.hubId
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(async () => {
    if (id) {
      const response = await getHubById(id)
      if (response.data.hub) {
        setData(response.data.hub)
        setvLogo(response.data.hub.logo)
        setvCoordinatorProfile(response.data.hub.coordinatorProfile)
        setIsLoading(false)
      }
    }
  }, [id, isLoading])

  useEffect(() => {
    getCountries()
      .then(response => {
        setCountries(response.data)
      })
      .catch(error => console.log(error))
  }, [])

  useEffect(() => {
    if (data.country)
      getState(data.country)
        .then(response => {
          setStates(response.data)
        })
        .catch(error => console.log(error))
  }, [data, countries])

  const breadcrumbItems = [
    { title: "zarela", link: "/" },
    { title: "hubs", link: "/hubs" },
    { title: "Edit", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Hubs", breadcrumbItems)
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    var validationName = document.getElementById("v-name").value
    var validationWebsite = document.getElementById("v-website").value
    var validationEmail = document.getElementById("v-email").value
    var validationPhone = document.getElementById("v-phone").value
    var validationCountry = document.getElementById("v-country").value
    var validationState = document.getElementById("v-state").value
    var validationAddress = document.getElementById("v-address").value
    var validationCoordinatorName =
      document.getElementById("v-coordinatorName").value
    var validationCoordinatorPhone =
      document.getElementById("v-coordinatorPhone").value

    var validationWorkinHours = []
    for (let i = 0; i < data.workingHours.length; i++) {
      let slise = document.getElementById(`v-workingHour-${i}`).value
      if (slise.trim() !== "") validationWorkinHours.push(slise)
    }

    var validationEquipments = []
    for (let i = 0; i < data.equipments.length; i++) {
      let slise = document.getElementById(`v-equipments-${i}`).value
      if (slise.trim() !== "") validationEquipments.push(slise)
    }

    var d1 = document.getElementsByName("validate")
    for (var i = 0; i < d1.length; i++) {
      d1[i].style.display = "block"
    }

    let response

    try {
      if (data) {
        delete data._id
        response = await updateHub(id, {
          logo: data.logo,
          name: validationName,
          website: validationWebsite,
          email: validationEmail,
          phone: validationPhone,
          country: validationCountry,
          city: validationState,
          address: validationAddress,
          coordinatorProfile: data.coordinatorProfile,
          coordinatorName: validationCoordinatorName,
          coordinatorPhone: validationCoordinatorPhone,
          workingHours: validationWorkinHours,
          equipments: validationEquipments,
        })
        if (response) {
          if (response.status === 200) {
            setShowSuccessMessage(true)
            history.push("/hub/" + id)
          }
        }
      }
    } catch (err) {
      setErrorMessage(err.response.data.message)
      setShowErrorMessage(true)
    }
  }

  return (
    <>
      <MetaTags>
        <title>Zarela | Update Hub</title>
      </MetaTags>
      <Row>
        {showSuccessMessage && (
          <SweetAlert
            confirmBtnText="Ok"
            confirmBtnBsStyle="success"
            title="Success"
            onConfirm={() => {
              setShowSuccessMessage(false)
            }}
          >
            Hub was updated successfuly
          </SweetAlert>
        )}
        {showErrorMessage && (
          <SweetAlert
            confirmBtnText="Ok"
            confirmBtnBsStyle="error"
            title="Error"
            onConfirm={() => setShowErrorMessage(false)}
          >
            {errorMessage}
          </SweetAlert>
        )}
        <Col xl={12}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">update hub form</h4>
              <AvForm
                className="needs-validation"
                onSubmit={e => handleSubmit(e)}
              >
                {/* logo */}
                <Row>
                  <Row className="mb-3">
                    <Col md={3} className="w-100 mb-3">
                      <Row className="w-100">
                        <label className="col-md-2 col-form-label">Logo</label>
                      </Row>
                      <img src={data?.logo} height={80} />
                    </Col>

                    <div className="col-md-12">
                      <input
                        type="file"
                        className="form-control form-control-file"
                        data-buttonname="btn-secondary"
                        placeholder="logo"
                        onChange={async acceptedFiles => {
                          const formData = new FormData()

                          if (acceptedFiles.target.files[0]) {
                            formData.append(
                              "file",
                              acceptedFiles.target.files[0]
                            )

                            let response
                            try {
                              response = await uploadImage(formData)
                            } catch (err) {
                              console.log("err", err)
                            }

                            if (response) {
                              setData({
                                ...data,
                                logo:
                                  process.env.REACT_APP_BASE_URL +
                                  "/" +
                                  response.data,
                              })
                            }
                          }
                        }}
                      />
                    </div>
                  </Row>

                  {/* hubs name */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">name</label>
                    <div className="col-md-10">
                      <AvField
                        name="name"
                        placeholder="hub's name"
                        type="text"
                        value={data?.name}
                        errorMessage="Enter Hub's Name"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="v-name"
                      />
                    </div>
                  </Row>

                  {/* web site */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">Website</label>
                    <div className="col-md-10">
                      <AvField
                        name="website"
                        placeholder="hub's website"
                        type="text"
                        value={data?.website}
                        errorMessage="Enter Hub Website"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="v-website"
                      />
                    </div>
                  </Row>

                  {/* email */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">email</label>
                    <div className="col-md-10">
                      <AvField
                        name="email"
                        placeholder="hub's email"
                        type="text"
                        value={data?.email}
                        errorMessage="Enter Hub's Email"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="v-email"
                      />
                    </div>
                  </Row>

                  {/* phone */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">Phone</label>
                    <div className="col-md-10">
                      <AvField
                        name="phone"
                        placeholder="hub's phone"
                        type="text"
                        value={data?.phone}
                        errorMessage="Enter Hub Phone"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="v-phone"
                      />
                    </div>
                  </Row>

                  {/* country */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">Country</label>
                    <div className="col-md-10">
                      <select
                        className="form-control"
                        id="v-country"
                        onChange={e => {
                          setData({
                            ...data,
                            country: e.target.value,
                          })
                        }}
                      >
                        {countries.map(item => {
                          return (
                            <option
                              selected={item.isoCode === data.country}
                              value={item.isoCode}
                            >
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Row>

                  {/* state */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">State</label>
                    <div className="col-md-10">
                      <select className="form-control" id="v-state">
                        {states.map(item => {
                          return (
                            <option
                              selected={item.name === data.city}
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Row>

                  {/* address */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">address</label>
                    <div className="col-md-10">
                      <AvField
                        name="address"
                        placeholder="hub's address"
                        type="text"
                        value={data?.address}
                        errorMessage="Enter Hub Address"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="v-address"
                      />
                    </div>
                  </Row>

                  {/* coordinatorProfile */}
                  <Row className="mb-3">
                    <Col md={12} className="w-100 mb-3">
                      <Row className="w-100">
                        <label className="col-md-2 col-form-label">
                          coordinator profile
                        </label>
                      </Row>
                      <img src={data?.coordinatorProfile} height={80} />
                    </Col>

                    <div className="col-md-12">
                      <input
                        type="file"
                        className="form-control form-control-file"
                        data-buttonname="btn-secondary"
                        placeholder="coordinator profile"
                        onChange={async acceptedFiles => {
                          const formData = new FormData()

                          if (acceptedFiles.target.files[0]) {
                            formData.append(
                              "file",
                              acceptedFiles.target.files[0]
                            )

                            let response
                            try {
                              response = await uploadImage(formData)
                            } catch (err) {
                              console.log("err", err)
                            }

                            if (response) {
                              setData({
                                ...data,
                                coordinatorProfile:
                                  process.env.REACT_APP_BASE_URL +
                                  "/" +
                                  response.data,
                              })
                            }
                          }
                        }}
                      />
                    </div>
                  </Row>

                  {/* coordinator name */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">
                      coordinator name
                    </label>
                    <div className="col-md-10">
                      <AvField
                        name="coordinatorName"
                        placeholder="coordinator name"
                        type="text"
                        value={data?.coordinatorName}
                        errorMessage="Enter Coordinator Name"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="v-coordinatorName"
                      />
                    </div>
                  </Row>

                  {/* coordinator phone */}

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">
                      coordinator phone
                    </label>
                    <div className="col-md-10">
                      <AvField
                        name="coordinatorPhone"
                        placeholder="coordinator phone"
                        type="text"
                        value={data?.coordinatorPhone}
                        errorMessage="Enter Coordinator Phone"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="v-coordinatorPhone"
                      />
                    </div>
                  </Row>

                  {/* working hours */}

                  <Row className="mb-3 ">
                    <Col xs={2} className="d-flex align-items-start">
                      <Row>
                        <label className="col-form-label">working hours</label>
                        <Button
                          color="primary"
                          type="button"
                          className="m-2"
                          onClick={() =>
                            setData({
                              ...data,
                              workingHours: [...data.workingHours, ""],
                            })
                          }
                        >
                          add field
                        </Button>
                      </Row>
                    </Col>

                    <div className="col-md-10">
                      {data?.workingHours?.map((item, index) => {
                        return (
                          <Row className="d-flex align-items-center justify-content-center">
                            <Col className="align-items-center">
                              <AvField
                                name="workingHour"
                                placeholder="working hour"
                                type="text"
                                value={item}
                                errorMessage="Enter working hour"
                                className="form-control mb-2"
                                id={`v-workingHour-${index}`}
                              />
                            </Col>

                            {/* <Col xs={1}>
                              <Button
                                color="danger"
                                type="button"
                                onClick={() =>
                                  setData({
                                    ...data,
                                    workingHours: [...data.workingHours.pup()],
                                  })
                                }
                              >
                                remove
                              </Button>
                            </Col> */}
                          </Row>
                        )
                      })}
                    </div>
                  </Row>

                  <Row className="mb-3 ">
                    <Col xs={2} className="d-flex align-items-start">
                      <Row>
                        <label className="col-form-label">equipmens</label>

                        <Button
                          color="primary"
                          type="button"
                          className="m-2"
                          onClick={() =>
                            setData({
                              ...data,
                              equipments: [...data.equipments, ""],
                            })
                          }
                        >
                          add field
                        </Button>
                      </Row>
                    </Col>
                    <div className="col-md-10">
                      {data?.equipments?.map((item, index) => {
                        return (
                          <AvField
                            name="workingHour"
                            placeholder="working hour"
                            type="text"
                            value={item}
                            errorMessage="Enter working hour"
                            className="form-control mb-2"
                            id={`v-equipments-${index}`}
                          />
                        )
                      })}
                    </div>
                  </Row>

                  {/* <Row className="mb-3 templating-select select2-container">
                    <label className="col-md-2 col-form-label">Keywords</label>
                    <div className="col-md-10">
                      <Select
                        value={data?.workingHours}
                        isMulti={true}
                        onChange={e => {
                          handleKeywords(e)
                        }}
                        options={optionKeywords}
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                      />
                    </div>
                  </Row> */}
                </Row>
                <Button color="primary mt-3" type="submit">
                  Update Hub
                </Button>
                <Button
                  color="default mt-3"
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Cancel
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(UpdateHubForm)
