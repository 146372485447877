import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { MDBDataTable, MDBCol, MDBCard, MDBCardBody } from "mdbreact"
import { setBreadcrumbItems } from "../../store/actions"
import {
  getAllKeywords,
  createKeyword,
  updateKeyword,
  getKeywordBySlug,
  deleteKeyword,
} from "client/ApiClient"
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

const Keywords = props => {
  const [keywordData, setKeywordData] = useState({})
  const [prevSlug, setPrevSlug] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [keywords, setKeywords] = useState([])

  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dynamic_description, setdynamic_description] = useState("")

  const [formState, setFormState] = useState("create")

  useEffect(async () => {
    const response = await getAllKeywords()
    if (response) {
      if (response.status === 200) {
        if (response.data.message === "Auth faild!") {
          window.location.replace("/login")
        }
        const res = response.data.keywords
        setKeywords(
          res.map(item => {
            return {
              ...item,
              actions: (
                <Row className="icon-demo-content Row justify-content-center align-items-center m-0 p-0">
                  <Col
                    xl="3"
                    lg="4"
                    sm="6"
                    className="m-0 p-0"
                    onClick={async () => {
                      setFormState("edit")
                      setPrevSlug(item.slug)
                      const res = await getKeywordBySlug(item.slug)
                      setKeywordData(res.data)
                    }}
                  >
                    <i className="mdi mdi-square-edit-outline" />
                  </Col>
                  <Col xl="3" lg="4" sm="6" className="m-0 p-0">
                    <i
                      className="mdi mdi-delete"
                      onClick={() => {
                        setconfirm_alert(true)
                        setPrevSlug(item.slug)
                      }}
                    />
                  </Col>
                </Row>
              ),
            }
          })
        )
      }
    }
  }, [showSuccessMessage])

  const breadcrumbItems = [
    { title: "zarela", link: "#" },
    { title: "keywords", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Keywords", breadcrumbItems)
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    var name = document.getElementById("name").value
    var slug = document.getElementById("slug").value
    var content = document.getElementById("content").value

    var d1 = document.getElementsByName("validate")
    for (var i = 0; i < d1.length; i++) {
      d1[i].style.display = "block"
    }

    let response
    try {
      if (formState === "create") {
        response = await createKeyword({
          name: name,
          content: content,
          slug: slug,
        })
      } else {
        response = await updateKeyword(prevSlug, {
          name: name,
          content: content,
          slug: slug,
        })
      }
      if (response) {
        if (response.status === 201) {
          setdynamic_description("Keyword created successfully")
          setShowSuccessMessage(true)
          setKeywordData({
            name: "",
            content: "",
            slug: "",
          })
          setFormState("create")
        } else if (response.status === 200) {
          setdynamic_description("Keyword updated successfully")
          setShowSuccessMessage(true)
          setKeywordData({
            name: "",
            content: "",
            slug: "",
          })
          setFormState("create")
        }
      }
    } catch (err) {
      setErrorMessage(err.response.data.message)
      setShowErrorMessage(true)
    }
  }

  const deleteHandler = () => {
    deleteKeyword(prevSlug)
    setdynamic_description("Keyword has been deleted successfully")
    setconfirm_alert(false)
    setShowSuccessMessage(true)
  }

  return (
    <>
      <MetaTags>
        <title>Zarela | Keywords</title>
      </MetaTags>
      <Row>
        <React.Fragment>
          {showSuccessMessage && (
            <SweetAlert
              confirmBtnText="Ok"
              confirmBtnBsStyle="success"
              title="Success"
              onConfirm={() => {
                setShowSuccessMessage(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          )}
          {showErrorMessage && (
            <SweetAlert
              confirmBtnText="Ok"
              confirmBtnBsStyle="error"
              title="Error"
              onConfirm={() => {
                setShowErrorMessage(false)
              }}
            >
              {errorMessage}
            </SweetAlert>
          )}

          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                deleteHandler()
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </React.Fragment>

        <Col xl={4}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">create new keyword form</h4>
              <AvForm
                className="needs-validation"
                onSubmit={e => handleSubmit(e)}
              >
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="name">Keyword Title</Label>
                      <AvField
                        name="name"
                        placeholder="Keyword Name"
                        type="text"
                        value={keywordData.name}
                        errorMessage="Enter Keyword Title"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="name"
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="slug">Keyword Slug</Label>
                      <AvField
                        name="slug"
                        placeholder="Keyword Slug"
                        type="text"
                        value={keywordData.slug}
                        errorMessage="Enter Keyword Slug"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          minLength: { value: 3, errorMessage: "Min 3 chars." },
                        }}
                        id="slug"
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        className="mb-3"
                        type="textarea"
                        value={keywordData.content}
                        label="Content "
                        name="content"
                        id="content"
                        rows="5"
                        placeholder="Enter Keyword Content"
                        errorMessage="This value is required."
                        validate={{
                          required: { value: true },
                          minLength: {
                            value: 10,
                            errorMessage: "Min 10 chars.",
                          },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Button color="primary" type="submit">
                  {formState === "edit" ? "Update Keyword" : "Create Keyword"}
                </Button>
                {formState === "edit" && (
                  <Button
                    color="secondary"
                    type="submit"
                    className="mx-2"
                    onClick={() => {
                      setFormState("create")
                      setKeywordData({
                        title: "",
                        content: "",
                        slug: "",
                      })
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </AvForm>
            </CardBody>
          </Card>
        </Col>
        <Col xs={8}>
          {keywords.length > 0 && (
            <MDBCol md="12">
              <MDBCard className="overflow-auto">
                <MDBCardBody>
                  <MDBDataTable
                    striped
                    bordered
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={{
                      columns: [
                        {
                          label: "name",
                          field: "name",
                        },
                        {
                          label: "slug",
                          field: "slug",
                        },
                        {
                          label: "content",
                          field: "content",
                        },
                        {
                          label: "actions",
                          field: "actions",
                        },
                      ],
                      rows: keywords,
                    }}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          )}
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(Keywords)
